import React, { createRef, useEffect, useRef, useState } from "react"
import Head from "../../components/global/head"
import ReCAPTCHA from 'react-google-recaptcha'

import { navigate } from "gatsby"
import MainLayout from "../../components/layouts/main_layout"

import { graphql, Link } from "gatsby"
import AddTapForm from "../../components/tapfinder/Form"
import GoogleMap from "../../components/tapfinder/GoogleMap"
import SearchBar from "../../components/tapfinder/SearchBar"

import { themes } from "../../components/buttons/primary_cta"
import PrimarySubmitCta from "../../components/buttons/primary_submit_cta"

import styles from "../../assets/styles/modules/tap_finder.module.scss"
import { Tldr } from "../../components/svgs/"
import JSONData from "../../data/taps.json"

import AddATapBackground from "../../../static/svgs/add_a_tap_bg.svg"
import Spinner from "../../components/spinner"

interface TapFinderProps {
  data: {
    allDatoCmsTap: {
      edges: Array<{
        node: {
          address: string
          id: string
          location: {
            latitude: string
            longitude: string
          }
          title: string
          isFormVisible: boolean
        }
      }>
    }
  }
}

const TapFinder = (props: TapFinderProps) => {
  const [location, setLocation] = useState({ lat: -38.123725, lng: 146.569962 })
  const [mapLoading, setMapLoading] = useState(true)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [state, setState] = useState({})

  const onPlaceChange = (place) => {
    setLocation({ lat: place.lat, lng: place.lng })
  }
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      onPlaceChange({
        lat: coords.latitude,
        lng: coords.longitude,
      })
    })
  }, [])

  // ------------------ MAP LOCATION DATA END POINTS ----------------------------

  // * Supplied JSON file stored in local file directory '../../data/taps.json'
  const jsonPlaces = JSONData.map((data) => ({
    id: data.id,
    location: data.name,
    address: data.location,
    position: {
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
    },
  }))

  // * DATO CMS
  const rawDato = props.data.allDatoCmsTap.edges
  const datoPlaces = rawDato.map(
    ({
      node: {
        id,
        title,
        address,
        location: { latitude, longitude },
      },
    }) => ({
      id,
      location: title,
      address,
      position: {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      },
    })
  )

  // ------------------ COMBINE THE TWO ENDPOINTS ----------------------------
  const places = [...jsonPlaces, ...datoPlaces]

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current?.getValue();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }
  return (
    <MainLayout>
      <Head
        title="Tap Finder"
        url="https://www.choosetap.com.au/tap-finder"
        description="Our handy tap-finder map allows you to locate your nearest refill point, add a tap or report one that is faulty."
        keywords="tap finder, tap-finder, tap water near me, fountain near me, find a fountain, choose tap app, refillers welcome map, refiller map"
        bodyClassname="body__tap-finder"
      />

      <Spinner isLoading={mapLoading} />
      <section id="tap-finder" className={styles.tapFinder}>
        <main>
          <aside
            className={`${styles.tapFinder__sideBar} ${
              isFormVisible ? "device-take-over" : ""
            }`}
          >
            <div className={styles.tapFinder__sideBar__content}>
              <div>
                <h1 className="typography__display--6 typography__color--white">
                  {!isFormVisible ? "Tap finder" : "Add a tap"}
                </h1>
                <div className="util__margin-top--18 util__margin-bottom--18 ">
                  <Tldr color="#fff" />
                </div>
                {!mapLoading && <SearchBar onPlaceChange={onPlaceChange} />}

                <AddTapForm
                  isVisible={isFormVisible}
                  formSubmit={handleSubmit}
                  handleChange={handleChange}
                  handleCancel={() => setIsFormVisible(!isFormVisible)}
                  recaptchaRef={recaptchaRef}
                />
              </div>
            </div>
            {!isFormVisible && (
              <div
                className={styles.tapFinder__sideBar__addATap}
                style={{ background: `url(${AddATapBackground})` }}
              >
                <div
                  className={`${styles.tapFinder__sideBar__content}
                    ${styles.tapFinder__sideBar__contentBottom}`}
                >
                  <div>
                    <h2 className="typography__diisplay--4 typography__color--white typography__ff--flama typography__text-trans--none">
                      Know about a tap that’s not on the map? Don’t keep it a
                      secret!
                    </h2>
                    <div className="util__padding-top--20  util__margin-bottom--55">
                      <PrimarySubmitCta
                        floatLeft={true}
                        disabled={false}
                        isSubmitButton={false}
                        theme={themes.white}
                        onClick={() => setIsFormVisible(!isFormVisible)}
                        label="Add a tap"
                      />
                    </div>
                    <small className="typography__diisplay--1 typography__color--white typography__ff--flamaMedium">
                      <Link
                        className="typography__color--white  typography__text-deco--underline"
                        to="/contact"
                      >
                        Report a broken or missing tap
                      </Link>
                    </small>
                  </div>
                </div>
              </div>
            )}
          </aside>
          <GoogleMap
            location={location}
            places={places}
            onMapLoading={() => setMapLoading(false)}
          />
        </main>
      </section>
    </MainLayout>
  )
}

// ? For Re-Captcha
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default TapFinder

export const query = graphql`
  query {
    allDatoCmsTap {
      edges {
        node {
          id
          address
          location {
            latitude
            longitude
          }
          title
        }
      }
    }
  }
`
