import React from "react"
import "./styles.scss"

interface SpinnerProps {
  isLoading: boolean
}

const Spinner = ({ isLoading }: SpinnerProps) => (
  <div id="spinner" className={`spinner ${isLoading ? "turn-on" : "turn-off"}`}>
    <div>
      <div className="container">
        <div className="loader"></div>
        <div className="loader"></div>
        <div className="loader"></div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter
            id="goo"
            filterUnits="objectBoundingBox"
            x="-100%"
            y="-100%"
            width="300%"
            height="300%"
          >
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="12"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 30 -9"
              result="goo"
            />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
    </div>
  </div>
)

export default Spinner
