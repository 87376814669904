import React, { useState } from "react"

import { Link } from "gatsby"

interface LinkCtaProps {
  isButton?: boolean
  label: string
  onClick?: () => void
  to?: string
}

const LinkCta = (props: LinkCtaProps) => {
  const { label, isButton, to, onClick } = props
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseOver = () => {
    setIsHovered(true)
  }

  const handleMouseOut = () => {
    setIsHovered(false)
  }
  return !isButton ? (
    <Link className="primary-cta link-cta util__opacity--5" to={`${to}`}>
      <span
        className={`primary-cta__label    typography__ff--flama  ${
          isHovered ? "invert" : ""
        } white_theme`}
      >
        {label}
      </span>
      <span
        className={`primary-cta__icon-wrapper primary-cta__icon-wrapper--right ${
          isHovered ? "expand" : ""
        }`}
      >
        <span
          className={`primary-cta__icon ${
            isHovered ? "shift" : ""
          } typography__color--white`}
        />
      </span>
    </Link>
  ) : (
    <button
      type="button"
      className="primary-cta link-cta link-cta--dir-left util__opacity--5  "
      onClick={onClick}
    >
      <span
        className={`primary-cta__label    typography__ff--flama  ${
          isHovered ? "invert" : ""
        } white_theme`}
      >
        {label}
      </span>
      <span
        className={`primary-cta__icon-wrapper ${isHovered ? "expand" : ""}`}
      >
        <span
          className={`primary-cta__icon ${
            isHovered ? "shift" : ""
          } typography__color--white`}
        />
      </span>
    </button>
  )
}

export default LinkCta
