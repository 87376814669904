import GoogleMap from "google-map-react"

import styles from "./mapStyles.json"

import React, { Component } from "react"
import { Marker } from "./Marker"

const API_KEY = process.env.GATSBY_GOOGLEMAPS_API

interface MapProps {
  location: {
    lat: number
    lng: number
  }
  places: Array<{
    id: string
    location: string
    address: string
    position: { lat: number; lng: number }
  }>
  onMapLoading: any
}

interface MapState {
  showInfoId?: string
}

export default class Map extends Component<MapProps, MapState> {
  constructor(props) {
    super(props)
    this.state = {
      showInfoId: undefined,
    }
  }

  onMakerClick = (clickedId?: string) => {
    this.setState({ showInfoId: clickedId })
  }

  render() {
    const { places, location } = this.props

    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMap
          bootstrapURLKeys={{
            key: `${API_KEY}&libraries=places`,
            language: "en",
            region: "au",
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          center={location}
          defaultZoom={16}
          options={{ styles }}
          onGoogleApiLoaded={() => this.props.onMapLoading()}
          onClick={() => this.onMakerClick()}
        >
          {places.map((place, index) => {
            return (
              <Marker
                key={index}
                lat={place.position.lat}
                lng={place.position.lng}
                showForm={place.id === this.state.showInfoId}
                onMarkerClick={() => this.onMakerClick(place.id)}
                place={place}
              />
            )
          })}
        </GoogleMap>
      </div>
    )
  }
}
