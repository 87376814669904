import React from "react"
import ggMarker from "../../assets/images/pin.png"
import styled from "styled-components"

import Popover from "react-bootstrap/Popover"
const popoverWidth = 300

interface MarkerProps {
  showForm: boolean
  lat: number
  lng: number
  place: {
    id: string
    location: string
    address: string
  }
  onMarkerClick: () => void
}
export const Marker = (props: MarkerProps) => {
  const { place, showForm, onMarkerClick } = props
  const { id, location, address } = place
  // console.log("location", location)

  return (
    <div>
      {showForm ? (
        <CustomPopover placement="top" id={`popover-positioned-${id}`}>
          <Popover.Title as="h3">{location}</Popover.Title>
          {address && <Popover.Content>{address}</Popover.Content>}
        </CustomPopover>
      ) : null}
      <MarkerIcon onClick={onMarkerClick} src={ggMarker} />
    </div>
  )
}

const MarkerIcon = styled.img`
  cursor: pointer;
  position: absolute;
  z-index: 9;
`

const CustomPopover = styled(Popover)`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 0;
  min-width: ${popoverWidth}px;
  padding: 15px;
  position: absolute;
  top: auto;
  transform: translateX(-${popoverWidth / 2 - 20}px);
  z-index: 10;
  .arrow {
    left: ${popoverWidth / 2 - 20}px;
  }
`
