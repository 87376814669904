import React, { LegacyRef, useState } from "react"
import ReCAPTCHA from 'react-google-recaptcha'
import { themes } from "../buttons/primary_cta"

import LinkCta from "../buttons/link_cta"

import { InputString } from "../forms/form_input"
import FormTextarea from "../forms/form_textarea"
import PrimarySubmitCta from "../buttons/primary_submit_cta"

interface FormProps {
  handleCancel: () => void
  handleChange: (e) => void
  formSubmit: (e) => void
  recaptchaRef: LegacyRef<ReCAPTCHA> | undefined
  isVisible: boolean
}

const recaptchaKey = process.env.SITE_RECAPTCHA_KEY || "";

const AddTapForm = (props: FormProps) => {
  const { formSubmit, handleChange, isVisible, recaptchaRef } = props
  const [buttonDisabled, setButtonDisabled] = useState(true)
  return (
    <form
      className={`form__add-a-tap ${
        isVisible
          ? "active util__show--transition  util__height--auto"
          : "util__hide--transition  util__height--0"
      }`}
      action="/tap-finder/thanks/"
      name="AddATap_Submisson"
      method="POST"
      data-netlify="true"
      data-netlify-recaptcha="true"
      netlify-honeypot="bot-field"
      onSubmit={formSubmit}
    >
      <noscript>
        <p>This form won’t work with Javascript disabled</p>
      </noscript>
      {/*
            Required hidden field for Netlify to work.
            see https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
          */}
      <input type="hidden" name="form-name" value="AddATap_Submisson" />

      {/* 
            Honeypot security field: If filled out then the submission will go to SPAM.
          */}
      <p hidden={true}>
        <label>
          Don’t fill this out if you're human:
          <input type="hidden" name="bot-field" value="AddATap_Submisson" />
        </label>
      </p>
      <div className="grid">
        <InputString
          label="Tap Name"
          name="tap_name"
          isInputRequired={true}
          onChange={handleChange}
          type="text"
        />
        <InputString
          label="Tap Address"
          name="tap_address"
          isInputRequired={true}
          onChange={handleChange}
          type="text"
        />
        <InputString
          label="Your Name"
          name="your_name"
          onChange={handleChange}
          type="text"
        />
        <InputString
          label="Email"
          name="email"
          onChange={handleChange}
          type="email"
        />

        <FormTextarea
          label="Tap Description"
          name="tap_description"
          onChange={handleChange}
        />
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={recaptchaKey}
        onChange={() => setButtonDisabled(false)}
      />
      <div className="grids__form-controls">
        <div>
          <LinkCta
            isButton={true}
            onClick={props.handleCancel}
            label="Cancel"
          />
        </div>
        <div>
          <PrimarySubmitCta
            isSubmitButton={true}
            theme={themes.white}
            label="Send"
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </form>
  )
}

export default AddTapForm
