import React, { useState } from "react"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import "./search_bar.scss"
import { InputString } from "../forms/form_input"
import Spinner from "../../components/spinner"

const SearchBar = ({ onPlaceChange }) => {
  const [address, setAddress] = useState("")

  const handleChange = (address) => {
    setAddress(address)
  }

  const handleSelect = (address) => {
    setAddress(address)
    document.location.href = "#"
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((place) => onPlaceChange(place))
      .catch((error) => console.error("Error", error))
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <div>
            <InputString
              {...getInputProps({
                placeholder: "Search",
                className: "location-search-input form-control",
              })}
              isSearch={true}
              label="Search"
              name="search_places"
              isInputRequired={true}
              type="text"
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item"
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      color: "#0085CF",
                      cursor: "pointer",
                    }
                  : {
                      backgroundColor: "#0085CF",
                      color: "#fafafa",
                      cursor: "pointer",
                    }
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default SearchBar
